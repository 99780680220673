import styled from 'styled-components';

export const Video = styled.video`
  width: 100%;
  height: auto;
`;

export const VideoContainer = styled.div`
  position: relative;
`;
