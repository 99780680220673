import styled from 'styled-components';

export const SignatureBigShouldersTitle = styled.h1`
  display: block;
  max-width: 250px;
  margin: 71px auto 80px;
  position: relative;
  font-family: ${({ theme }) => theme.fonts.bigShouldersDisplay};
  font-weight: 800;
  font-size: 60px;
  line-height: 100%;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};

  & > i {
    display: block;
    position: absolute;
    top: -71px;
    left: -17px;
    font-family: ${({ theme }) => theme.fonts.jvSignature};
    font-weight: 100;
    font-size: 68px;
    font-style: normal;
    line-height: 110%;
    text-transform: none;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.text};
    transform: rotate(-10deg);
  }
`;
