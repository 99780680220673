import type * as i from 'types';
import * as React from 'react';

import SlantedArrowIcon from 'vectors/internal/slantedArrow.svg';

import {
  LinkContainer,
  ExtendendSubText,
  ExtendendText,
  TextContainer,
  Icon,
  LinkInner,
} from './styled';

const LinkWithEmoji: React.FC<Props> = ({ url, urlType, title, icon }) => {
  const linkPrefix = urlType === 'email' ? 'mailto:' : '';

  return (
    <LinkContainer>
      <LinkInner href={`${linkPrefix}${url}`}>
        <Icon>{icon}</Icon>
        <TextContainer>
          <ExtendendSubText>{title}</ExtendendSubText>
          <ExtendendText>{url}</ExtendendText>
        </TextContainer>
        <SlantedArrowIcon />
      </LinkInner>
    </LinkContainer>
  );
};

export type Props = i.TypeComponentLinkWithEmojiFields;

export default LinkWithEmoji;
