import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Text } from 'common/typography';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const ExtendedText = styled(Text)`
  display: flex;
  text-transform: uppercase;
`;

export const Arrow = styled(motion.span)`
  display: block;
  margin: 0 0 0 12px;
`;
