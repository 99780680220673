import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

/**
 * These Headline components are directly ported from the component library of
 * Build in Amsterdam, for more information see:
 * https://www.figma.com/file/puxYhqQBPsho8jJS5Q2WgH/Rocycle-Components?node-id=193%3A0
 */

export const Headline1 = styled.h1<Headline1Props>`
  font-family: ${({ theme }) => theme.fonts.bigShouldersDisplay};
  font-style: normal;
  font-weight: 800;
  font-feature-settings: 'calt' off;
  line-height: 100%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text};
  margin: 0;

  ${({ size }) => size === 'xl' && css`
    font-size: 20vw;

    ${media.desktop`
      font-size: 144px;
    `}
  `}

  ${({ size }) => size === 'm' && css`
    font-size: 96px;
  `}

  ${({ size }) => size === 's' && css`
    font-size: 56px;
    letter-spacing: -0.02em;
  `}
`;

type Headline1Props = {
  size: 'xl' | 'm' | 's';
};

export const Headline2 = styled.h2<Headline2Props>`
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  letter-spacing: -0.04em;
  font-feature-settings: 'ss04' off, 'calt' off;
  color: ${({ theme }) => theme.colors.text};
  margin: 0;

  ${({ size }) => size === 'xl' && css`
    font-size: 42px;

    ${media.desktop`
      font-size: 48px;
    `}
  `}

  ${({ size }) => size === 'm' && css`
    font-size: 24px;

    ${media.desktop`
      font-size: 30px;
    `}
  `}

  ${({ variant }) => variant === 'reckless' && css`
    font-family: ${({ theme }) => theme.fonts.recklessNeue};
    line-height: 130%;
  `}

  ${({ variant }) => variant === 'bigShoulders' && css`
    font-family: ${({ theme }) => theme.fonts.bigShouldersDisplay};
    font-style: normal;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: -0.02em;
  `}
`;

type Headline2Props = {
  size?: 'xl' | 'm' | 's';
  variant?: 'reckless' | 'bigShoulders';
};

export const Headline3 = styled.h3<Headline3Props>`
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  letter-spacing: -0.03em;
  font-feature-settings: 'ss04' on, 'calt' off;
  color: ${({ theme }) => theme.colors.text};
  margin: 0;

  ${({ variant }) => variant === 'reckless' && css`
    font-family: ${({ theme }) => theme.fonts.recklessNeue};
  `}
`;

type Headline3Props = {
  variant?: 'reckless';
};

export const Headline4 = styled.h4<Headline4Props>`
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  line-height: 120%;
  letter-spacing: -0.03em;
  font-feature-settings: 'ordn' on, 'ss04' on, 'ss06' on, 'liga' off;
  color: ${({ theme }) => theme.colors.text};
  margin: 0;

  ${({ variant }) => variant === 'reckless' && css`
    font-family: ${({ theme }) => theme.fonts.recklessNeue};
    line-height: 125%;
    letter-spacing: -0.02em;
    font-feature-settings: 'ordn' on, 'liga' off;
  `}
`;

type Headline4Props = {
  variant?: 'reckless';
};
