import styled from 'styled-components';

export const RichTextContainer = styled.div`
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  color: ${({ theme }) => theme.colors.text};
  letter-spacing: -0.01em;
  font-size: 18px;
  line-height: 125%;
  text-align: left;

  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5,
  & > h6 {
    font-weight: 200;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Paragraph = styled.p<ParagraphProps>`
  font-family: ${({ theme, $hasSerifModifier }) =>
    $hasSerifModifier ? theme.fonts.recklessNeue : theme.fonts.favoritPro} !important;
`;

type ParagraphProps = {
  $hasSerifModifier?: boolean;
};

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px 0;
  margin: auto;
  border: none;
  background: none;
`;
