import * as i from 'types';
import React from 'react';
import Head from 'next/head';

import { useRouter } from 'hooks';

export const Meta: React.FC<MetaProps> = ({ pageName, meta, article = false }) => {
  const router = useRouter();

  const seo = {
    title: meta?.title || `${pageName} - Rocycle`,
    description: meta?.description || 'Get Fired Up',
    image: meta?.image?.fields.file.url
      ? // 1200 * 627 is the recommended seo image resolution
        `https:${meta?.image?.fields.file.url}?w=1200&h=627&fit=thumb&f=face`
      : '/images/logo_rocycle.png',
    // Remove hash from meta url
    url: router.asPath.split('#')[0],
  };

  const robots: string[] = [];
  if (meta?.noIndex) robots.push('noindex');
  if (meta?.noFollow) robots.push('nofollow');

  return (
    <Head>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {article && <meta property="og:type" content="article" />}
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@rocyclestudios" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      {robots.length > 0 && <meta name="robots" content={robots.join(', ')} />}
    </Head>
  );
};

type MetaProps = {
  pageName: string;
  meta?: i.TypeSeoFields;
  article?: boolean;
};
