import styled from 'styled-components';

export const StyledImage = styled.img<StyledImageProps>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: ${({ $position }) => $position};
`;

type StyledImageProps = {
  $position: string;
};
