import styled from 'styled-components';

import { FocalPointImage } from 'common/layout/FocalPointImage';

export const Thumbnail = styled(FocalPointImage)`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
  background-color: ${({ theme }) => theme.colors.silver};
`;
