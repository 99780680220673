import Link from 'next/link';
import styled from 'styled-components';

import { media } from 'styles/utils';
import { Text } from 'common/typography';

export const Icon = styled.div`
  display: grid;
  place-items: center;
`;

export const LinkContainer = styled.div`
  padding-bottom: 30px;
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
`;

export const LinkInner = styled(Link)`
  display: grid;
  grid-template-columns: 10% auto 10%;
  margin: 0 auto;
  text-decoration: none;

  ${media.desktop`
    max-width: 600px;
  `}
`;

export const ExtendendText = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  font-size: 18px;
  line-height: 125%;
`;

export const ExtendendSubText = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  font-size: 14px;
  line-height: 115%;
  opacity: 0.7;
  margin-bottom: 4px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;
