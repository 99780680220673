import type * as i from 'types';
import * as React from 'react';
import Link from 'next/link';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import {
  type Block,
  type Inline,
  type Text,
  type Hyperlink,
  BLOCKS,
} from '@contentful/rich-text-types';
import type { Asset, EntryFields } from 'contentful';

import { VideoPlayerSmall } from 'common/general';
import { Button } from 'common/interaction';
import LinkWithEmoji from 'modules/contentful/LinkWithEmoji';

import { RichTextContainer, Image, Paragraph, ButtonContainer } from './styled';

export const RichText = ({ children, body, ...props }: RichTextProps) => {
  const textTransform = (node: Block | Inline) => {
    const { content, nodeType } = node as Inline;

    const nodeMap = {
      paragraph: 'p',
      'heading-1': 'h1',
      'heading-2': 'h2',
      'heading-3': 'h3',
      'heading-4': 'h4',
      'heading-5': 'h5',
      'heading-6': 'h6',
    };

    // Check if there is a node that starts with a backslash (e.g. "\lorem ipsum\")
    const hasSerifMod = content.some(
      (item) => item.nodeType === 'text' && /^\\.*/.test(item.value),
    );

    return (
      <Paragraph as={nodeMap[nodeType]} $hasSerifModifier={hasSerifMod}>
        {content.map((item, i) => {
          if (item.nodeType === 'hyperlink') {
            const { content } = node as Inline;

            // Find the item that contains the data for this node
            const item = content.find((item): item is Hyperlink => item.nodeType === 'hyperlink');

            if (!item) return null;

            // Find the items that contains the text for this node
            const text = item.content.find((c): c is Text => c.nodeType === 'text')?.value || '';
            const isExternalLink = !item.data.uri.includes(process.env.NEXT_PUBLIC_SITE_URL!);

            // A link stylized as a button (e.g. "<see all>")
            if (/^<.*>$/.test(text)) {
              return (
                <ButtonContainer as="button">
                  <Button href={item.data.uri || '/'} target="_blank">
                    {text.replace(/<|>/g, '')}
                  </Button>
                </ButtonContainer>
              );
            }

            return (
              <Link href={item.data.uri || '/'} target={isExternalLink ? '_blank' : undefined}>
                {text}
              </Link>
            );
          }

          if (item.nodeType !== 'text') return null;

          let text = item.value;

          // Check if backslash is at start or end to prevent deleting other backslashes
          const hasSerifMod = /^\\|\\$/.test(text);
          if (hasSerifMod) {
            text = text.replace(/\\/g, '');
          }

          if (item.marks.find((mark) => mark.type === 'bold')) {
            return <b key={i}>{text}</b>;
          }

          if (item.marks.find((mark) => mark.type === 'italic')) {
            return <i key={i}>{text}</i>;
          }

          return <React.Fragment key={i}>{text}</React.Fragment>;
        })}
      </Paragraph>
    );
  };

  const options: Options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { file, title } = (node.data.target as Asset).fields;

        if (file.contentType.includes('image')) {
          return <Image src={file.url} alt={title} />;
        }

        return <VideoPlayerSmall file={file} />;
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if (node.nodeType === 'embedded-entry-block') {
          const fields = node.data.target.fields as i.TypeComponentLinkWithEmojiFields;

          if (fields.type === 'link_with_emoji') {
            return <LinkWithEmoji {...fields} />;
          }
        }

        return null;
      },
      [BLOCKS.PARAGRAPH]: textTransform,
      [BLOCKS.HEADING_1]: textTransform,
      [BLOCKS.HEADING_2]: textTransform,
      [BLOCKS.HEADING_3]: textTransform,
      [BLOCKS.HEADING_4]: textTransform,
      [BLOCKS.HEADING_5]: textTransform,
      [BLOCKS.HEADING_6]: textTransform,
    },
  };

  return (
    <RichTextContainer {...props}>
      {typeof children === 'function'
        ? children(documentToReactComponents(body as Document, options))
        : documentToReactComponents(children as Document, options)}
    </RichTextContainer>
  );
};

type RichTextProps = {
  className?: string;
} & (
  | {
      children: Block | Inline | EntryFields.RichText;
      body?: undefined;
    }
  | {
      children?: (body: React.ReactNode) => React.ReactNode;
      body: Block | Inline | EntryFields.RichText;
    }
);
