import { motion } from 'framer-motion';
import styled from 'styled-components';

import Logo from 'vectors/internal/rocycle-logo.svg';

export const ExtendedBlock = styled(motion.div)<ExtendedBlockProps>`
  width: 100%;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.white};
  height: ${({ $isFullScreen }) => ($isFullScreen ? '100vh' : '93.5vh')};
  transition: height 0.5s ease-in-out;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
`;

type ExtendedBlockProps = {
  $isFullScreen: boolean;
};

export const LogoContainer = styled(motion.div)`
  position: absolute;
  display: flex;
  justify-content: center;
`;

export const VideoBlockLogoIcon = styled(Logo)`
  color: ${({ theme }) => theme.colors.white};
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`;

export const ProgressContainer = styled.div`
  height: 2px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
`;

// Use attrs based approach to change width, because regenerating classes
// very often is bad for performance
export const ProgressBar = styled.div.attrs((props: ProgressBarProps) => ({
  style: {
    transform: `scaleX(${props.percentagePlayed / 100})`,
  },
}))<ProgressBarProps>`
  transition: transform 0.5s;
  transform-origin: left;
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;

type ProgressBarProps = {
  percentagePlayed: number;
};

export const ProgressTime = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  font-size: 14px;
  position: absolute;
  z-index: 1;
  left: 20px;
  bottom: 20px;
  user-select: none;
`;

export const AudioToggle = styled.span`
  position: absolute;
  z-index: 1;
  right: 20px;
  bottom: 20px;
  user-select: none;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  font-size: 14px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
`;
