import * as i from 'types';
import * as React from 'react';
import { motion, HTMLMotionProps } from 'framer-motion';

import { getFocalPointImage } from 'services';

import { StyledImage } from './styled';

export const FocalPointImage: React.FC<FocalPointImageProps> = ({
  image,
  alt,
  sizes,
  withAnimation,
  fallback,
  ...props
}) => {
  const imageData = getFocalPointImage(image, sizes);

  if (!imageData) {
    return (
      <StyledImage
        src={fallback || props.src}
        $position="0% 0%"
        {...(withAnimation && { as: motion.img })}
        {...props}
      />
    );
  }

  return (
    <StyledImage
      alt={alt || imageData.alt}
      src={imageData.src}
      srcSet={imageData.srcSet}
      sizes={sizes || '(max-width: 768px) 768px, (max-width: 992px) 992px, 1920px'}
      $position={imageData.position}
      {...(withAnimation && { as: motion.img })}
      {...props}
    />
  );
};

type FocalPointImageProps = HTMLMotionProps<'img'> & {
  image?: i.TypeImageWithFocalPointFields;
  fallback?: string;
  alt?: string;
  sizes?: string;
  withAnimation?: boolean;
};
