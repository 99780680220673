import * as i from 'types';
import styled, { css } from 'styled-components';

export const Text = styled.p<i.TextDefaultProps>`
  line-height: 22px;
  font-size: ${({ $size }) => $size || 15}px;
  font-weight: ${({ $weight }) => $weight || 400};
  color: ${({ theme, $color }) => theme.colors[$color || 'text']};
  margin: ${({ $margin }) => $margin || 0};
  letter-spacing: -0.01em;

  ${({ $variant }) =>
    $variant === 'reckless' &&
    css`
      font-family: ${({ theme }) => theme.fonts.recklessNeue};
    `}

  ${({ $inline }) =>
    $inline &&
    css`
      display: inline-block;
    `}

  ${({ $noSelect }) =>
    $noSelect &&
    css`
      user-select: none;
    `}

  ${({ $isCenter }) =>
    $isCenter &&
    css`
      text-align: center;
    `}

  & > a {
    color: ${({ theme, $color }) => theme.colors[$color || 'text']};
    text-decoration: underline;
  }
`;
