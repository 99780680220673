import * as React from 'react';
import { useTranslation } from 'next-i18next';

import { Container, ExtendedText, Arrow } from './styled';

export const ScrollIndicator: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <ExtendedText>
        <span>{t('Scroll for all')}</span>
        <Arrow
          animate={{ y: [-7, -2, 0, 2, 7], opacity: [0, 1, 1, 1, 0] }}
          transition={{ repeat: Infinity, duration: 1.5, ease: 'linear' }}
        >
          ↓
        </Arrow>
      </ExtendedText>
    </Container>
  );
};
