import * as React from 'react';
import { useTranslation } from 'next-i18next';

import { AudioToggle } from 'modules/blocks/VideoBlock/styled';

import { Video, VideoContainer } from './styled';

export const VideoPlayerSmall: React.FC<Props> = ({ file }) => {
  const { t } = useTranslation();
  const [isMuted, mute] = React.useState(true);

  return (
    <VideoContainer>
      <Video autoPlay muted={isMuted} loop>
        <source src={file.url} type={file.contentType} />
      </Video>
      <AudioToggle onClick={() => mute((isMuted) => !isMuted)}>
        {isMuted ? t('Audio off') : t('Audio on')}
      </AudioToggle>
    </VideoContainer>
  );
};

type Props = {
  file: {
    url: string;
    contentType: string;
  };
};
