import * as React from 'react';

import { Container } from './styled';

export const Spinner: React.FC = () => (
  <Container>
    <div />
    <div />
    <div />
    <div />
  </Container>
);
